body {
    --cc-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    --cc-font-size: 16px;
    --cc-bg: #fff;
    --cc-z-index: 2147483647;
    --cc-text: #343334;
    --cc-border-radius: 0.45rem;
    --cc-btn-primary-bg: #f96112;
    --cc-btn-primary-text: var(--cc-bg);
    --cc-btn-primary-hover-bg: #c44e0e;
    --cc-btn-primary-hover-text: var(--cc-btn-primary-text);
    --cc-btn-secondary-bg: #d8dde0;
    --cc-btn-secondary-text: #f96112;
    --cc-btn-secondary-hover-bg: #babfc2;
    --cc-btn-secondary-hover-text: var(--cc-btn-secondary-text);
    --cc-btn-border-radius: 0.375rem;
    --cc-toggle-bg-off: #919ea6;
    --cc-toggle-bg-on: var(--cc-btn-primary-bg);
    --cc-toggle-bg-readonly: #d5dee2;
    --cc-toggle-knob-bg: #fff;
    --cc-toggle-knob-icon-color: #ecf2fa;
    --cc-block-text: var(--cc-text);
    --cc-cookie-category-block-bg: #f0f4f7;
    --cc-cookie-category-block-bg-hover: #e9eff4;
    --cc-section-border: #f1f3f5;
    --cc-cookie-table-border: #e9edf2;
    --cc-overlay-bg: #040608;
    --cc-overlay-opacity: 0.85;
    --cc-consent-modal-box-shadow: 0 0.625rem 1.875rem rgba(2, 2, 3, 0.28);
    --cc-webkit-scrollbar-bg: #cfd5db;
    --cc-webkit-scrollbar-bg-hover: #9199a0;
}